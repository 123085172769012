.sponsor-us-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #eae2b7;
}
.sponsor-us-banner {
  width: 100%;
  height: 244px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: 48px;
  padding-right: 48px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(90deg, rgb(214, 40, 40) 2.00%,rgba(252, 191, 73, 0.75) 52.00%,rgba(0, 48, 73, 0.8) 100.00%),url("/uberbotsimage5-min-1500w.jpeg");
  background-position: center;
}
.sponsor-us-text {
  color: white;
  font-size: 2em;
  align-self: center;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.sponsor-us-navlink {
  top: 0px;
  right: 0px;
  width: 13%;
  position: absolute;
  align-self: flex-end;
  text-align: center;
  font-family: Georgia;
  border-color: #ff0000;
  border-width: 2px;
  text-decoration: none;
  background-color: #fcbf49;
}
.sponsor-us-text01 {
  font-size: 2em;
  align-self: flex-start;
  font-style: normal;
  margin-top: 4%;
  font-family: Noto Sans;
  font-weight: 700;
  margin-left: 2%;
}
.sponsor-us-text02 {
  width: auto;
  align-self: flex-start;
  margin-top: 2%;
  text-align: left;
  font-family: Georgia;
  line-height: 2.52;
  margin-left: 2%;
}
.sponsor-us-container1 {
  gap: 5%;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: flex-start;
  margin-top: 2%;
  align-items: center;
  margin-left: 2%;
  margin-right: 2%;
  justify-content: flex-start;
}
.sponsor-us-navlink1 {
  flex: auto;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  border-color: red;
  border-width: 2px;
  text-decoration: none;
  background-color: #fcbf49;
}
.sponsor-us-navlink2 {
  flex: auto;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  border-color: red;
  border-width: 2px;
  text-decoration: none;
  background-color: #fcbf49;
}
.sponsor-us-navlink3 {
  font-style: normal;
  font-weight: 600;
  border-color: #ff0000;
  border-width: 2px;
  text-decoration: none;
  background-color: #fcbf49;
}
.sponsor-us-image {
  width: 50%;
  height: 30%;
  align-self: flex-start;
  margin-top: 2%;
  object-fit: cover;
}
@media(max-width: 767px) {
  .sponsor-us-banner {
    padding-left: 32px;
    padding-right: 32px;
  }
  .sponsor-us-navlink {
    width: 95px;
  }
  .sponsor-us-text01 {
    align-self: flex-start;
  }
  .sponsor-us-text02 {
    align-self: flex-start;
  }
}
@media(max-width: 479px) {
  .sponsor-us-banner {
    padding-top: 48px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 48px;
  }
  .sponsor-us-navlink {
    top: 0px;
    right: 0px;
    width: 84px;
    height: auto;
    margin: auto;
    font-size: 18px;
    text-align: center;
    font-family: Georgia;
    text-decoration: none;
    background-color: yellow;
  }
  .sponsor-us-text01 {
    align-self: flex-start;
  }
  .sponsor-us-text02 {
    width: auto;
    text-align: left;
  }
  .sponsor-us-container1 {
    align-self: flex-start;
  }
}
