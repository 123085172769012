.donate-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.donate-hero {
  width: 100%;
  height: 1030px;
  display: flex;
  padding: 48px;
  position: relative;
  min-height: 80vh;
  align-items: flex-start;
  padding-right: 48px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #eae2b7;
}
.donate-header {
  width: 110%;
  height: 244px;
  display: flex;
  max-width: 1500%;
  margin-top: -50px;
  align-items: center;
  margin-left: -50px;
  padding-top: 32px;
  padding-left: 0px;
  padding-bottom: 32px;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(214, 40, 40) 2.00%,rgba(252, 191, 73, 0.75) 52.00%,rgba(0, 48, 73, 0.8) 100.00%),url("/uberbotsimage5-min-1800w.jpeg");
  background-position: center;
}
.donate-nav {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
}
.donate-text {
  color: rgb(0, 0, 0);
}
.donate-text01 {
  color: white;
}
.donate-btn-group {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.donate-burger-menu {
  display: none;
}
.donate-icon {
  width: 16px;
  height: 16px;
}
.donate-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.donate-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.donate-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  justify-content: space-between;
}
.donate-image {
  height: 2rem;
}
.donate-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.donate-icon02 {
  width: 16px;
  height: 16px;
}
.donate-icon04 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.donate-icon06 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.donate-icon08 {
  width: 16px;
  height: 16px;
}
.donate-navlink {
  top: 0px;
  right: 0px;
  width: 13%;
  position: absolute;
  align-self: flex-end;
  text-align: center;
  font-family: Georgia;
  border-color: #ff0000;
  border-width: 2px;
  text-decoration: none;
  background-color: #fcbf49;
}
.donate-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
}
.donate-text03 {
  top: 0px;
  left: 0px;
  width: auto;
  position: absolute;
  text-align: left;
}
.donate-text05 {
  font-family: Georgia;
  font-weight: 700;
}
.donate-text08 {
  font-family: Georgia;
}
.donate-text09 {
  font-style: normal;
  font-family: Georgia;
  font-weight: 700;
}
.donate-text10 {
  color: var(--dl-color-gray-500);
  font-family: Georgia;
}
.donate-text12 {
  color: var(--dl-color-gray-500);
  font-family: Georgia;
}
.donate-text13 {
  color: var(--dl-color-gray-500);
  font-family: Georgia;
}
.donate-text14 {
  color: var(--dl-color-gray-500);
  font-family: Georgia;
}
.donate-text15 {
  color: var(--dl-color-gray-500);
  font-family: Georgia;
}
.donate-text16 {
  color: var(--dl-color-gray-500);
  font-family: Georgia;
}
.donate-text17 {
  color: var(--dl-color-gray-500);
  font-family: Georgia;
}
.donate-text18 {
  color: var(--dl-color-gray-500);
  font-family: Georgia;
}
.donate-text22 {
  font-family: Georgia;
  font-weight: 700;
}
.donate-text25 {
  font-family: Georgia;
}
.donate-text26 {
  font-family: Georgia;
  font-weight: 700;
}
.donate-text27 {
  font-family: Georgia;
}
.donate-text29 {
  color: var(--dl-color-gray-500);
  font-family: Georgia;
}
.donate-text31 {
  color: var(--dl-color-gray-500);
  font-family: Georgia;
}
@media(max-width: 1200px) {
  .donate-text05 {
    font-family: Georgia;
    font-weight: 700;
  }
  .donate-text08 {
    font-family: Georgia;
  }
  .donate-text09 {
    font-style: normal;
    font-family: Georgia;
    font-weight: 700;
  }
  .donate-text10 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
  .donate-text22 {
    font-family: Georgia;
    font-weight: 700;
  }
  .donate-text25 {
    font-family: Georgia;
  }
  .donate-text26 {
    font-family: Georgia;
    font-weight: 700;
  }
  .donate-text27 {
    font-family: Georgia;
  }
  .donate-text29 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
  .donate-text31 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
}
@media(max-width: 991px) {
  .donate-header {
    width: 115%;
  }
}
@media(max-width: 767px) {
  .donate-hero {
    padding-left: 32px;
    padding-right: 32px;
  }
  .donate-header {
    width: 115%;
    padding-left: 32px;
    padding-right: 32px;
  }
  .donate-nav {
    display: none;
  }
  .donate-btn-group {
    display: none;
  }
  .donate-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .donate-icon {
    display: none;
  }
  .donate-navlink {
    width: 95px;
  }
}
@media(max-width: 479px) {
  .donate-hero {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .donate-header {
    width: 110%;
    height: 25%;
    padding: var(--dl-space-space-unit);
    align-self: flex-end;
    margin-left: -30px;
  }
  .donate-text {
    width: 243px;
    text-align: center;
    margin-right: 0px;
  }
  .donate-mobile-menu {
    padding: 16px;
  }
  .donate-navlink {
    width: 89px;
  }
  .donate-text04 {
    font-family: Georgia;
  }
  .donate-text05 {
    font-family: Georgia;
    font-weight: 700;
  }
  .donate-text06 {
    font-family: Georgia;
    font-weight: 700;
  }
  .donate-text07 {
    font-family: Georgia;
  }
  .donate-text08 {
    font-family: Georgia;
  }
  .donate-text09 {
    font-style: normal;
    font-family: Georgia;
    font-weight: 700;
  }
  .donate-text10 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
  .donate-text11 {
    font-family: Georgia;
  }
  .donate-text12 {
    font-family: Georgia;
  }
  .donate-text13 {
    font-family: Georgia;
  }
  .donate-text14 {
    font-family: Georgia;
  }
  .donate-text15 {
    font-family: Georgia;
  }
  .donate-text16 {
    font-family: Georgia;
  }
  .donate-text17 {
    font-family: Georgia;
  }
  .donate-text18 {
    font-family: Georgia;
  }
  .donate-text19 {
    font-family: Georgia;
  }
  .donate-text20 {
    font-family: Georgia;
  }
  .donate-text21 {
    font-family: Georgia;
  }
  .donate-text22 {
    font-family: Georgia;
    font-weight: 700;
  }
  .donate-text23 {
    font-family: Georgia;
    font-weight: 700;
  }
  .donate-text24 {
    font-family: Georgia;
  }
  .donate-text25 {
    font-family: Georgia;
  }
  .donate-text26 {
    font-family: Georgia;
    font-weight: 700;
  }
  .donate-text27 {
    font-family: Georgia;
  }
  .donate-text28 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
  .donate-text29 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
  .donate-text30 {
    font-family: Georgia;
  }
  .donate-text31 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
}
