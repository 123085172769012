.achievements-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.achievements-hero {
  width: 100%;
  height: 1030px;
  display: flex;
  padding: 48px;
  position: relative;
  min-height: 80vh;
  align-items: flex-start;
  padding-right: 48px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #eae2b7;
}
.achievements-header {
  width: 110%;
  height: 244px;
  display: flex;
  max-width: 1500%;
  margin-top: -50px;
  align-items: center;
  margin-left: -50px;
  padding-top: 32px;
  padding-left: 0px;
  padding-bottom: 32px;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(214, 40, 40) 2.00%,rgba(252, 191, 73, 0.75) 52.00%,rgba(0, 48, 73, 0.8) 100.00%),url("/uberbotsimage5-min-1700w.jpeg");
  background-position: center;
}
.achievements-nav {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
}
.achievements-text {
  color: rgb(0, 0, 0);
}
.achievements-text01 {
  color: white;
}
.achievements-btn-group {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.achievements-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.achievements-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.achievements-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  justify-content: space-between;
}
.achievements-image {
  height: 2rem;
}
.achievements-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.achievements-icon {
  width: 16px;
  height: 16px;
}
.achievements-icon2 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.achievements-icon4 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.achievements-icon6 {
  width: 16px;
  height: 16px;
}
.achievements-navlink {
  top: 0px;
  right: 0px;
  width: 13%;
  position: absolute;
  align-self: flex-end;
  text-align: center;
  font-family: Georgia;
  border-color: #ff0000;
  border-width: 2px;
  text-decoration: none;
  background-color: #fcbf49;
}
.achievements-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
}
.achievements-text03 {
  top: 0px;
  left: -40px;
  width: auto;
  position: absolute;
  align-self: stretch;
  text-align: left;
}
.achievements-text06 {
  font-weight: 700;
}
.achievements-text11 {
  font-weight: 700;
}
.achievements-text16 {
  font-weight: 700;
}
.achievements-text21 {
  font-weight: 700;
}
.achievements-text26 {
  font-weight: 700;
}
.achievements-text31 {
  font-weight: 700;
}
.achievements-text36 {
  font-weight: 700;
}
.achievements-text41 {
  font-weight: 700;
}
.achievements-text46 {
  font-weight: 700;
}
.achievements-text51 {
  font-weight: 700;
}
.achievements-text56 {
  font-weight: 700;
}
.achievements-text61 {
  font-weight: 700;
}
.achievements-text66 {
  font-weight: 700;
}
.achievements-text67 {
  font-style: normal;
  font-weight: 400;
}
.achievements-image1 {
  top: 314px;
  right: 16px;
  width: 30%;
  height: 40%;
  position: absolute;
  object-fit: cover;
  border-color: #ff0000;
  border-width: 3px;
}
@media(max-width: 1200px) {
  .achievements-navlink {
    top: 0px;
    right: 0px;
  }
  .achievements-text03 {
    width: 60%;
  }
  .achievements-image1 {
    width: 40%;
  }
}
@media(max-width: 991px) {
  .achievements-text03 {
    width: 100%;
  }
  .achievements-image1 {
    top: 110%;
    left: 0px;
    right: 0px;
    width: 80%;
    margin: auto;
  }
}
@media(max-width: 767px) {
  .achievements-hero {
    padding-left: 32px;
    padding-right: 32px;
  }
  .achievements-header {
    align-self: flex-start;
    margin-left: -35px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .achievements-nav {
    display: none;
  }
  .achievements-btn-group {
    display: none;
  }
  .achievements-navlink {
    width: 95px;
  }
}
@media(max-width: 479px) {
  .achievements-container {
    align-items: center;
    justify-content: flex-start;
  }
  .achievements-hero {
    height: 100%;
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .achievements-header {
    top: 0px;
    flex: 0;
    right: 0px;
    width: 100%;
    height: 20%;
    margin: auto;
    padding: var(--dl-space-space-unit);
    position: absolute;
    align-self: stretch;
  }
  .achievements-text {
    font-size: 28px;
  }
  .achievements-mobile-menu {
    padding: 16px;
  }
  .achievements-navlink {
    top: 0px;
    right: 0px;
    width: 84px;
    height: auto;
    margin: auto;
    font-size: 18px;
    text-align: center;
    font-family: Georgia;
    text-decoration: none;
    background-color: yellow;
  }
  .achievements-text03 {
    top: 120px;
    left: 0px;
    width: 100%;
  }
  .achievements-image1 {
    top: auto;
    left: var(--dl-space-space-halfunit);
    right: auto;
    width: 100%;
    bottom: -700px;
  }
}
