.mentor-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #eae2b7;
}
.mentor-hero {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 80vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #eae2b7;
}
.mentor-header {
  width: 100%;
  height: 244px;
  display: flex;
  align-items: center;
  padding-top: 32px;
  padding-left: 0px;
  padding-bottom: 32px;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(214, 40, 40) 2.00%,rgba(252, 191, 73, 0.75) 52.00%,rgba(0, 48, 73, 0.8) 100.00%),url("/uberbotsimage5-min-1800w.jpeg");
  background-position: center;
}
.mentor-nav {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
}
.mentor-text {
  color: rgb(0, 0, 0);
}
.mentor-text01 {
  color: white;
}
.mentor-text02 {
  color: white;
}
.mentor-btn-group {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.mentor-burger-menu {
  display: none;
}
.mentor-icon {
  width: 16px;
  height: 16px;
}
.mentor-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.mentor-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.mentor-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  justify-content: space-between;
}
.mentor-image {
  height: 2rem;
}
.mentor-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mentor-icon02 {
  width: 16px;
  height: 16px;
}
.mentor-icon04 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.mentor-icon06 {
  width: 16px;
  height: 16px;
  margin-right: 32px;
}
.mentor-icon08 {
  width: 16px;
  height: 16px;
}
.mentor-navlink {
  top: 0px;
  right: 0px;
  width: 13%;
  position: absolute;
  align-self: flex-end;
  text-align: center;
  font-family: Georgia;
  border-color: #ff0000;
  border-width: 2px;
  text-decoration: none;
  background-color: #fcbf49;
}
.mentor-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  position: relative;
  align-items: center;
  justify-content: flex-start;
  background-color: #eae2b7;
}
.mentor-text03 {
  width: 50%;
  height: auto;
  text-align: left;
  font-family: Georgia;
}
.mentor-text05 {
  font-weight: 700;
}
.mentor-text11 {
  color: var(--dl-color-gray-500);
}
.mentor-text14 {
  color: var(--dl-color-gray-500);
}
.mentor-text17 {
  color: var(--dl-color-gray-500);
}
.mentor-text20 {
  color: var(--dl-color-gray-500);
}
.mentor-text23 {
  color: var(--dl-color-gray-500);
}
.mentor-text26 {
  color: var(--dl-color-gray-500);
}
.mentor-text29 {
  color: var(--dl-color-gray-500);
}
.mentor-text32 {
  font-weight: 700;
}
.mentor-text42 {
  font-weight: 700;
}
.mentor-text46 {
  color: var(--dl-color-gray-500);
}
.mentor-text50 {
  color: var(--dl-color-gray-500);
}
.mentor-text54 {
  color: var(--dl-color-gray-500);
}
.mentor-text58 {
  color: var(--dl-color-gray-500);
}
.mentor-text63 {
  color: var(--dl-color-gray-500);
}
.mentor-div {
  width: 40%;
  min-height: 600px;
}
@media(max-width: 1200px) {
  .mentor-hero {
    height: auto;
  }
  .mentor-text03 {
    width: 100%;
    font-family: Georgia;
  }
  .mentor-text05 {
    font-family: Georgia;
    font-weight: 700;
  }
  .mentor-text11 {
    color: var(--dl-color-gray-500);
  }
  .mentor-text13 {
    font-family: Georgia;
  }
  .mentor-text14 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
  .mentor-text16 {
    font-family: Georgia;
  }
  .mentor-text17 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
  .mentor-text19 {
    font-family: Georgia;
  }
  .mentor-text20 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
  .mentor-text22 {
    font-family: Georgia;
  }
  .mentor-text23 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
  .mentor-text25 {
    font-family: Georgia;
  }
  .mentor-text26 {
    font-family: Georgia;
  }
  .mentor-text28 {
    font-family: Georgia;
  }
  .mentor-text29 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
  .mentor-text32 {
    font-family: Georgia;
    font-weight: 700;
  }
  .mentor-text35 {
    font-family: Georgia;
  }
  .mentor-text37 {
    font-family: Georgia;
  }
  .mentor-text39 {
    font-family: Georgia;
  }
  .mentor-text42 {
    font-family: Georgia;
    font-weight: 700;
  }
  .mentor-text45 {
    font-family: Georgia;
  }
  .mentor-text46 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
  .mentor-text49 {
    font-family: Georgia;
  }
  .mentor-text50 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
  .mentor-text53 {
    font-family: Georgia;
  }
  .mentor-text54 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
  .mentor-text57 {
    font-family: Georgia;
  }
  .mentor-text58 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
  .mentor-text61 {
    font-family: Georgia;
  }
  .mentor-text62 {
    font-family: Georgia;
  }
  .mentor-text63 {
    color: var(--dl-color-gray-500);
    font-family: Georgia;
  }
  .mentor-div {
    top: 130%;
    left: 0px;
    right: 0px;
    width: 100%;
    bottom: 42px;
    margin: auto;
  }
}
@media(max-width: 991px) {
  .mentor-div {
    top: 140%;
  }
}
@media(max-width: 767px) {
  .mentor-hero {
    padding-left: 32px;
    padding-right: 32px;
  }
  .mentor-header {
    width: 120%;
    margin-left: -50px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .mentor-nav {
    display: none;
  }
  .mentor-btn-group {
    display: none;
  }
  .mentor-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mentor-navlink {
    width: 95px;
  }
  .mentor-text03 {
    width: 100%;
    font-family: Georgia;
  }
  .mentor-text05 {
    font-weight: 700;
  }
  .mentor-text11 {
    color: var(--dl-color-gray-500);
  }
  .mentor-text14 {
    color: var(--dl-color-gray-500);
  }
  .mentor-text17 {
    color: var(--dl-color-gray-500);
  }
  .mentor-text20 {
    color: var(--dl-color-gray-500);
  }
  .mentor-text23 {
    color: var(--dl-color-gray-500);
  }
  .mentor-text29 {
    color: var(--dl-color-gray-500);
  }
  .mentor-text32 {
    font-weight: 700;
  }
  .mentor-text42 {
    font-weight: 700;
  }
  .mentor-text46 {
    color: var(--dl-color-gray-500);
  }
  .mentor-text50 {
    color: var(--dl-color-gray-500);
  }
  .mentor-text54 {
    color: var(--dl-color-gray-500);
  }
  .mentor-text58 {
    color: var(--dl-color-gray-500);
  }
  .mentor-text63 {
    color: var(--dl-color-gray-500);
  }
  .mentor-div {
    top: 145%;
    left: 0px;
    right: 0px;
    width: 100%;
    margin: auto;
  }
}
@media(max-width: 479px) {
  .mentor-hero {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
  .mentor-header {
    height: 150px;
    padding: var(--dl-space-space-unit);
    margin-top: -35px;
    margin-left: -10px;
  }
  .mentor-text {
    color: rgb(0, 0, 0);
    font-size: 28px;
  }
  .mentor-text01 {
    color: white;
  }
  .mentor-mobile-menu {
    padding: 16px;
  }
  .mentor-navlink {
    width: 84px;
    text-align: center;
    padding-right: 14px;
  }
  .mentor-div {
    top: 140%;
    left: -15px;
    width: 470px;
    margin: 0px;
  }
}
